<template>
	<div>
		<s-crud
			add
			edit
			remove
			:config="config"
			title="Compromiso Conductor"
			@save="save($event)"
			@EditEvent="editEvent($event)"
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="12" lg="4" md="4" sm="12" xs="12" v-if="!isEdit">
							<s-toolbar-person 
								
								label="N°. Documento Conductor" 
								return-object 
								@returnPerson="hadReturnPerson($event, props.item)"
							/>
						</v-col>
						<v-col cols="12" :lg="isEdit ? 8 : 4" :md="isEdit ? 8 : 4" sm="12" xs="12">
							<s-text
								disabled
								label="Conductor"
								v-model="DrvName"
							>
							</s-text>
						</v-col>
						<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<s-load 
								label="Subir Compromiso"
								v-model="fileLetter"
							>
							</s-load>
							<!-- <s-load label="subir archivo (BK)" v-model="fileBk"></s-load> -->
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:letter="{ row }">
				<v-btn 
					icon 
					color="primary"
					@click="downloadLetter(row)"
				>
					<v-icon>fas fa-file-download</v-icon>
				</v-btn>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sSleDriverCommitmentService from "@/services/Sale/SleDriverCommitmentService.js"
	import _sHelper from "@/services/HelperService";
	import sToolbarPerson from "@/components/TecnicalAssistence/ProducersFarms/sToolbarPerson";

	export default {
		components: {
			sToolbarPerson,
		},

		data() {
			return {
				config: {
					model: {
						DcmID: "ID",
						letter: "string",
						PrsDocumentNumber: "string"
					},
					service: _sSleDriverCommitmentService,
					headers: [
						{text: "ID", value: "DcmID"},
						{text: "N° Documento", value: "PrsDocumentNumber"},
						{text: "Conductor", value: "DrvName"},
						{text: "Carta", value: "letter"},
					]
				},
				fileLetter: [],
				objPerson: [],
				DrvName: "",
				isEdit: false
			}
		},

		methods: {
			save(item) {
				if(this.objPerson != null){
					item.DrvID = this.objPerson.PrsID;
				}
				else{
					this.$fun.alert("Ingrese Conductor", "warning");
				}
				item.DcmLetter = this.fileLetter != null ? this.fileLetter.name : '';
				item.save().then(resp => {
					this.uploadFileLetter();
					this.DrvName = "";
					this.objPerson = []
				});
			},

			uploadFileLetter()
			{
				if(this.fileLetter != null){
					var formData = new FormData();
					formData.append("file", this.fileLetter);
					_sHelper.uploadfile(1, this.$fun.getUserID(), formData, 9)
					.then(resp => {
						if(resp.status == 200){
							this.fileLetter = [];
							return;
						}
					})
				} else {
					this.$fun.alert("Sin datos que subir");
				}
			},

			hadReturnPerson(person, item)
			{
				if(person != null){
					this.objPerson = person;
					this.DrvName = person.PrdCardName;
				}
			},

			downloadLetter(row)
			{
				if(row.DcmLetter != '' || row.DcmLetter != null || row.DcmLetter == undefined){
					this.$fun.alert("¿Seguro descargar Carta de Compromiso?", "question")
					.then(resp => {
						if(resp.value){
							_sHelper.getfile(1, this.$fun.getUserID(), row.DcmLetter, 9).then((r) => {
								const url = window.URL.createObjectURL(new Blob([r.data]));
								const link = document.createElement("a");
								link.href = url;
								link.setAttribute("download", row.DcmLetter);
								document.body.appendChild(link);
								link.click();
							});
						}
					})
				}
			},

			editEvent(item)
			{
				this.isEdit = true;
				this.DrvName = item[0].DrvName;
			}
		},
	}
</script>